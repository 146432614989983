<template>
  <div class="row">
    <div class="col-sm-12 col-md-2 col-lg-2">
      <q-form ref="editForm">
        <!-- 상세 -->
        <c-card title="LBLDETAIL" class="cardClassDetailForm">
          <template slot="card-button">
            <q-btn-group outline>
              <!-- 저장 -->
              <c-btn
                v-if="editable"
                :isSubmit="isSave"
                :url="saveUrl"
                :param="matrix"
                :mappingType="mappingType"
                label="LBLSAVEALL"
                icon="save"
                @beforeAction="saveMatrix"
                @btnCallback="saveMatrixCallback"
              />
            </q-btn-group>
          </template>
          <template slot="card-detail">
            <div class="col-12">
              <c-plant
                :required="true"
                :disabled="isOld"
                :editable="editable"
                type="edit"
                name="plantCd"
                v-model="matrix.plantCd"
              />
            </div>
            <div class="col-12">
              <!-- 평가기법 -->
              <c-select
                :editable="editable"
                :disabled="isOld"
                codeGroupCd="RAM_TECHNIQUE_CD"
                :required="true"
                type="edit"
                itemText="codeName"
                itemValue="code"
                name="ramTechniqueCd"
                label="LBL0001031"
                v-model="matrix.ramTechniqueCd"
              ></c-select>
            </div>
            <div class="col-12">
              <!-- Matrix명 -->
              <c-text
                :editable="editable"
                :required="true"
                label="LBL0001254"
                name="matrixName"
                v-model="matrix.matrixName"
              >
              </c-text>
            </div>
            <div class="col-12">
              <!-- 비고 -->
              <c-textarea
                :editable="editable"
                label="LBLREMARK"
                name="remarks"
                v-model="matrix.remarks"
              >
              </c-textarea>
            </div>
            <div class="col-12">
              <!-- 사용여부 -->
              <c-radio
                :editable="editable"
                :comboItems="useFlagItems"
                label="LBLUSEFLAG"
                name="useFlag"
                v-model="matrix.useFlag"
              >
              </c-radio>
            </div>
          </template>
        </c-card>
      </q-form>
    </div>
    <div class="col-sm-12 col-md-3 col-lg-3">
      <!-- 위험도 기준 -->
      <c-card
        title="LBL0001032"
        class="cardClassDetailForm risk-standards"
      >
        <template slot="card-button">
          <q-btn-group outline>
            <!-- Matrix 기준 적용 -->
            <c-btn
              v-if="editable"
              label="색상 적용"
              icon="check"
              @btnClicked="acceptStandardMatrixRisk"
            />
            <!-- <c-btn
              v-if="editable"
              label="위험도범위 반영"
              icon="check"
              @btnClicked="acceptMatrixRisk"
            /> -->
            <!-- <c-btn label="LBLAPPLY" icon="check" @btnClicked="accept" /> -->
            <c-btn v-if="editable" :showLoading="false" label="" icon="add" @btnClicked="addR" />
            <c-btn v-if="editable" :showLoading="false" label="" icon="remove" @btnClicked="removeR" />
          </q-btn-group>
        </template>
        <template slot="card-detail">
          <div
            class="col-12"
            v-for="(range, idx) in matrix.standards"
            :key="idx"
          >
            <q-item
              style="align-items: center;"
              v-ripple
            >
              <q-item-section side top>
                <q-checkbox v-if="editable" v-model="range.checked"></q-checkbox>
              </q-item-section>
              <div class="cursor-pointer risk-name" style="min-width: 90px;max-width: 130px;">
                {{ range.riskContents }}
                <q-popup-edit v-model="range.riskContents" :disable="!editable" content-class="bg-accent text-white"
                  @save="datachange(range)">
                  <q-input dark color="white" v-model="range.riskContents" dense autofocus>
                    <template v-slot:append>
                      <q-icon name="edit"></q-icon>
                    </template>
                  </q-input>
                </q-popup-edit>
              </div>
              <q-range
                v-model="range.model"
                :disable="!editable"
                :min="rangeComp.min"
                :max="rangeComp.max"
                :step="1"
                label-always
                drag-range
                label
                markers
                snap
                class="q-mr-md"
                @input="datachange(range)"
              />
              <q-badge :style="{ 'background-color': range.color }">
                <!-- 색상지정 -->
                {{$label('LBL0001263')}}
                <q-popup-proxy>
                  <q-color
                    v-model="range.color"
                    no-header
                    no-footer
                    class="my-picker"
                    @input="datachange(range)"
                  />
                </q-popup-proxy>
              </q-badge>
            </q-item>
          </div>
        </template>
      </c-card>
    </div>
    <div class="col-sm-12 col-md-7 col-lg-7">
      <!-- 위험도 -->
      <c-card
        title="LBL0001011"
        class="cardClassDetailForm risk"
        :noMarginPadding="true"
      >
        <template slot="card-button">
          <q-btn-group outline>
            <!-- 선택된 series - cell 클릭 후 값을 변경하여 위험도를 설정 -->
            <c-text
              ref="selectSeries"
              :editable="editable"
              :disabled="setSeriesDisabled"
              name="selectSeries"
              :beforeText="$label('LBL0001257')"
              v-model="selectSeries.y"
              @dataChange="change"
            >
            </c-text>
            <!-- 빈도&강도 적용 -->
            <c-btn
              v-if="editable"
              label="LBL0001258"
              icon="check"
              @btnClicked="accept"
            />
          </q-btn-group>
        </template>
        <template slot="card-detail">
          <div class="col-4">
            <q-toolbar class="bg-primary text-white" style="min-height:20px;">
              <q-toolbar-title>{{Boolean(riskData) ? `위험도 : ${riskData}` : '차트 Tooltip'}}</q-toolbar-title>
            </q-toolbar>
            <q-toolbar class="bg-teal text-white" style="min-height:20px;">
              <q-toolbar-title>빈도</q-toolbar-title>
            </q-toolbar>
            <q-list bordered>
              <q-item style="padding: 4px 8px;">
                <q-item-section>
                  <q-item-label>
                    <span v-html="frequencyData"></span>
                  </q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
            <q-toolbar class="bg-teal text-white" style="min-height:20px;">
              <q-toolbar-title>강도</q-toolbar-title>
            </q-toolbar>
            <q-list bordered>
              <q-item style="padding: 4px 8px;">
                <q-item-section>
                  <q-item-label>
                    <span v-html="strengtyData"></span>
                  </q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </div>
          <div class="col-8">
            <apexchart
              ref="chart"
              :width="chartWidth"
              height="350"
              type="heatmap"
              :options="chartOptions"
              :series="series"
              @dataPointSelection="dataPointSelection"
              @mouseMove="mouseMove"
              @mouseLeave="mouseLeave"
            >
            </apexchart>
          </div>
        </template>
      </c-card>
    </div>
    <div class="col-12">
      <!-- Matrix 설정 영역 -->
      <c-card
        title="LBL0001260"
        class="cardClassDetailForm"
        :noMarginPadding="true"
      >
        <template slot="card-detail">
          <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
            <!-- 빈도 -->
            <c-table
              ref="tableF"
              title="LBL0001009"
              tableId="f01"
              :hideBottom="true"
              :usePaging="false"
              :filtering="false"
              :columns="gridFColumns"
              :data="matrix.frequencies"
              :gridHeight="gridF.height"
              selection="multiple"
              rowKey="ramMatrixFrequencyId"
              :editable="editable"
            >
              <!-- 버튼 영역 -->
              <template slot="table-button">
                <q-btn-group outline>
                  <!-- 추가 -->
                  <c-btn
                    v-if="editable"
                    :showLoading="false"
                    label="LBLADD"
                    icon="add"
                    @btnClicked="addF"
                  />
                  <c-btn
                    v-if="editable"
                    :showLoading="false"
                    label="제외"
                    icon="remove"
                    @btnClicked="removeF"
                  />
                </q-btn-group>
              </template>
            </c-table>
          </div>
          <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
            <!-- 강도 -->
            <c-table
              ref="tableS"
              title="LBL0001010"
              tableId="s01"
              :hideBottom="true"
              :usePaging="false"
              :filtering="false"
              :columns="gridSColumns"
              :data="matrix.strengties"
              :gridHeight="gridS.height"
              selection="multiple"
              rowKey="ramMatrixStrengthId"
              :editable="editable"
            >
              <!-- 버튼 영역 -->
              <template slot="table-button">
                <q-btn-group outline>
                  <!-- 추가 -->
                  <c-btn
                    v-if="editable"
                    :showLoading="false"
                    label="LBLADD"
                    icon="add"
                    @btnClicked="addS"
                  />
                  <c-btn
                    v-if="editable"
                    :showLoading="false"
                    label="제외"
                    icon="remove"
                    @btnClicked="removeS"
                  />
                </q-btn-group>
              </template>
            </c-table>
          </div>
        </template>
      </c-card>
    </div>
  </div>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from "@/js/selectConfig";
import transactionConfig from "@/js/transactionConfig";
import VueApexCharts from "vue-apexcharts";
export default {
  name: "matrix",
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    popupParam: {
      type: Object,
      default: function () {
        return {
          ramMatrixId: "",
        };
      },
    },
    contentHeight: {
      type: [String, Number],
      default: function () {
        return "auto";
      },
    },
  },
  data() {
    return {
      matrix: {
        ramMatrixId: "", // matrix 번호
        matrixName: "", // matrix 명
        plantCd: "", // 사업장코드
        ramTechniqueCd: null, // 평가기법 코드
        remarks: "", // 비고
        useFlag: "Y", // 사용여부
        regUserId: "", // 등록자 ID
        chgUserId: "", // 수정자 ID
        frequencies: [], // 빈도
        deleteFrequencies: [], // [삭제] 빈도
        strengties: [], // 강도
        deleteStrengties: [], // [삭제] 강도
        risks: [], // 위험도
        standards: [], // 위험도 기준
        deleteStandards: [], // [삭제] 위험도 기준
        matrixRiskStandardCd: null,
      },
      chartWidth: "80%",
      chartOptions: {
        tooltip: {
          enabled: true,
          custom: () => {
            return "";
          },
        },
        dataLabels: {
          enabled: true,
          style: {
            colors: ["#000000"],
          },
        },
        colors: ["#3f6ad8"],
        toolbar: {
          show: false,
        },
        plotOptions: {
          heatmap: {
            enableShades: false,
            colorScale: {
              ranges: [],
            },
          },
        },
        xaxis: {
          type: "category",
          tooltip: {
            enabled: false,
          },
          title: {
            text: "강도",
          },
        },
        yaxis: {
          show: true,
          tooltip: {
            enabled: false,
          },
          title: {
            text: "빈도",
          },
        },
      },
      rangeComp: {
        min: 0,
        max: 0,
      },
      series: [],
      selectSeries: {
        x: "",
        y: null,
      },
      standards:[],
      gridF: {
        height: "350px",
      },
      gridS: {
        height: "350px",
      },
      frequencyData: "",
      strengtyData: "",
      riskData: "",
      useFlagItems: [
        { code: "Y", codeName: "사용" },
        { code: "N", codeName: "미사용" },
      ],
      editable: true,
      detailUrl: "",
      saveUrl: "",
      isSave: false,
      mappingType: "POST",
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  computed: {
    isOld () {
      return Boolean(this.popupParam.ramMatrixId);
    },
    setSeriesDisabled() {
      return this.selectSeries.y >= 0 ? false : true;
    },
    gridFColumns() {
      let cols = []
      if (this.matrix.ramTechniqueCd === 'RT00000020') {
        // KRAS
        cols = [
          {
            name: "frequency",
            field: "frequency",
            // 빈도
            label: "LBL0001009",
            align: "center",
            style: "width:50px",
            type: "text",
            sortable: true,
            required: true,
          },
          {
            name: 'accidentOccurred',
            field: 'accidentOccurred',
            label: '사고발생',
            align: 'left',
            style: "width:120px",
            type: "textarea",
            sortable: true,
          },
          {
            name: 'workFrequencyDaily',
            field: 'workFrequencyDaily',
            label: '작업빈도(日)',
            align: 'left',
            style: "width:120px",
            type: "textarea",
            sortable: true,
          },
          {
            name: 'safetyDevicesRegulationsYear',
            field: 'safetyDevicesRegulationsYear',
            label: '안전장치/규정(年)',
            align: 'left',
            style: "width:120px",
            type: "textarea",
            sortable: true,
          },
          {
            name: 'musculoskeletalFactorsYear',
            field: 'musculoskeletalFactorsYear',
            label: '근골격계요인(年)',
            align: 'left',
            style: "width:120px",
            type: "textarea",
            sortable: true,
          },
        ]
      } else {
        // HAZOP
        cols = [
          {
            name: "frequency",
            field: "frequency",
            // 빈도
            label: "LBL0001009",
            align: "center",
            style: "width:50px",
            type: "text",
            sortable: true,
            required: true,
          },
          {
            name: 'frequencyContents',
            field: 'frequencyContents',
            label: '내용',
            align: 'left',
            style: "width:300px",
            type: "textarea",
            sortable: true,
            required: true,
          },
        ]
      }
      return cols;
    },
    gridSColumns() {
      let cols = []
      if (this.matrix.ramTechniqueCd === 'RT00000020') {
        // KRAS
        cols = [
          {
            name: "strength",
            field: "strength",
            // 강도
            label: "LBL0001010",
            align: "center",
            style: "width:50px",
            type: "text",
            sortable: true,
            required: true,
          },
          {
            name: 'humanDamageYear',
            field: 'humanDamageYear',
            label: '인적피해(年)',
            align: 'left',
            style: "width:120px",
            type: "textarea",
            sortable: true,
          },
          {
            name: 'lossAmountYear',
            field: 'lossAmountYear',
            label: '손실금액(年)',
            align: 'left',
            style: "width:120px",
            type: "textarea",
            sortable: true,
          },
          {
            name: 'lineStopYear',
            field: 'lineStopYear',
            label: 'Line 정지(年)',
            align: 'left',
            style: "width:120px",
            type: "textarea",
            sortable: true,
          },
          {
            name: 'workingEnvironmentYear',
            field: 'workingEnvironmentYear',
            label: '작업환경(年)',
            align: 'left',
            style: "width:120px",
            type: "textarea",
            sortable: true,
          },
          {
            name: 'musculoskeletalSystemSpecialistYear',
            field: 'musculoskeletalSystemSpecialistYear',
            label: '근골격계양성자(年)',
            align: 'left',
            style: "width:120px",
            type: "textarea",
            sortable: true,
          },
        ]
      } else {
        // HAZOP
        cols = [
          {
            name: "strength",
            field: "strength",
            // 강도
            label: "LBL0001010",
            align: "center",
            style: "width:50px",
            type: "text",
            sortable: true,
            required: true,
          },
          {
            name: 'strengthContents',
            field: 'strengthContents',
            label: '내용',
            align: 'left',
            style: "width:300px",
            type: "textarea",
            sortable: true,
            required: true,
          },
        ]
      }
      return cols;
    },
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.ram.matrix.get.url;
      this.saveUrl = transactionConfig.ram.matrix.insert.url;
      // code setting
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.ramMatrixId) {
        this.$http.url = this.$format(
          this.detailUrl,
          this.popupParam.ramMatrixId
        );
        this.$http.type = "GET";
        this.$http.request((_result) => {
          this.$_.extend(this.matrix, _result.data);
          if (this.matrix.standards && this.matrix.standards.length > 0) {
            this.$_.forEach(this.matrix.standards, (item) => {
              this.$set(item, "checked", false);
              this.$set(item, "model", {
                min: item.min,
                max: item.max,
              });
            });
            this.$forceUpdate();
          }
          /**
           * risk를 가지고 있는지 판단
           * 있을 시에 처음 데이터 가져와서 그릴때 참조
           *
           * 그 후에 빈도, 강도를 변경하여 새롭게 적용을 할 시에는 haveRisk의 값은 false 처리되어
           * 빈도와 강도에 따라 위험도가 다시 결정되게 처리
           */
          this.haveRisk = this.matrix.risks && this.matrix.risks.length > 0;
          this.setChart();
        });
      } 
    },
    /* eslint-disable no-unused-vars */
    setChart() {
      this.rangeComp.max = 0;
      let x = this.$_.map(this.matrix.strengties, "strength");
      let y = [];
      this.chartOptions.xaxis.categories = x;
      this.$_.forEach(this.matrix.frequencies, (item) => {
        y.push({
          name: item.frequency,
          data: this.generateData(
            this.matrix.strengties.length,
            item.frequency
          ),
        });
      });
      this.series = y;
      let ranges = [];
      if (this.matrix.standards && this.matrix.standards.length > 0) {
        this.$_.forEach(this.matrix.standards, (range) => {
          ranges.push({
            from: range.min,
            to: range.max,
            name: range.riskContents,
            color: range.color,
          });
        });
      }
      this.chartOptions.plotOptions.heatmap.colorScale.ranges = ranges;
      this.chartOptions.dataLabels.formatter = (val, opts) => {
        // 위험도 : 
        return this.$comm.getLangLabel('LBL0001270') + opts.value;
      };

      setTimeout(() => {
        this.chartWidth = "95%";
      }, 300);
      this.$refs["chart"].refresh();
    },
    generateData(count, fData) {
      let i = 0;
      let series = [];
      while (i < count) {
        let data = this.matrix.strengties[i];
        let risk = this.$_.find(this.matrix.risks, {
          frequency: fData,
          strength: data.strength,
        });
        let x = (i + 1).toString();
        let y = 0;

        if (this.haveRisk) {
          y = Number(risk.risk);
          // 초반에 저장된 기준으로 matrix를 구성하기 위한 설정
          this.$_.forEach(this.matrix.risks, (item) => {
            this.rangeComp.max =
              Number(item.risk) > Number(this.rangeComp.max)
                ? Number(item.risk)
                : Number(this.rangeComp.max);
          });
        } else {
          y =
            !isNaN(data.strength) && !isNaN(fData)
              ? Number(data.strength) * Number(fData)
              : 0;
          this.rangeComp.max = y > this.rangeComp.max ? y : this.rangeComp.max;
        }

        series.push({
          x: x,
          y: y,
        });
        i++;
      }
      return series;
    },
    dataPointSelection(event, chartContext, config) {
      this.selectSeries =
        this.series[config.seriesIndex].data[config.dataPointIndex];
      if (this.$refs["selectSeries"]) {
        this.$refs["selectSeries"].$children[0].focus();
      }
    },
    mouseMove(event, chartContext, config) {
      if (config.dataPointIndex > -1 && config.seriesIndex > -1) {
        this.frequencyData = '';
        if (this.matrix.frequencies[config.seriesIndex] && this.matrix.ramTechniqueCd === 'RT00000020') {
          // KRAS
          this.frequencyData = `<b>사고발생</b><br/>
 ${this.matrix.frequencies[config.seriesIndex].accidentOccurred}<br/>
<b>작업빈도(日)</b><br/>
 ${this.matrix.frequencies[config.seriesIndex].workFrequencyDaily}<br/>
<b>안전장치/규정(年)</b><br/>
 ${this.matrix.frequencies[config.seriesIndex].safetyDevicesRegulationsYear}<br/>
<b>근골격계요인(年)</b><br/>
 ${this.matrix.frequencies[config.seriesIndex].musculoskeletalFactorsYear}`
        } else if (this.matrix.frequencies[config.seriesIndex] && this.matrix.ramTechniqueCd === 'RT00000001') {
          // HAZOP
          this.frequencyData = this.$comm.convertEnter(this.matrix.frequencies[config.seriesIndex].frequencyContents)
        }

        this.strengtyData = '';
        if (this.matrix.strengties[config.dataPointIndex] && this.matrix.ramTechniqueCd === 'RT00000020') {
          // KRAS
          this.strengtyData = `<b>인적피해(年)</b><br/>
 ${this.matrix.strengties[config.dataPointIndex].humanDamageYear}<br/>
<b>손실금액(年)</b><br/>
 ${this.matrix.strengties[config.dataPointIndex].lossAmountYear}<br/>
<b>Line 정지(年)</b><br/>
 ${this.matrix.strengties[config.dataPointIndex].lineStopYear}<br/>
<b>작업환경(年)</b><br/>
 ${this.matrix.strengties[config.dataPointIndex].workingEnvironmentYear}<br/>
<b>근골격계양성자(年)</b><br/>
 ${this.matrix.strengties[config.dataPointIndex].musculoskeletalSystemSpecialistYear}`
        } else if (this.matrix.strengties[config.dataPointIndex] && this.matrix.ramTechniqueCd === 'RT00000001') {
          // HAZOP
          this.strengtyData = this.$comm.convertEnter(this.matrix.strengties[config.dataPointIndex].strengthContents)
        }

        this.riskData =
          config.config.series[config.seriesIndex].data[
            config.dataPointIndex
          ].y;
      }
    },
    mouseLeave(event, chartContext, config) {
      this.frequencyData = "";
      this.strengtyData = "";
      this.riskData = "";
    },
    change() {
      this.$refs["chart"].refresh();
    },
    addF() {
      this.matrix.frequencies.push({
        ramMatrixFrequencyId: uid(),
        ramMatrixId: this.popupParam.ramMatrixId,
        frequency: "",
        frequencyContents: '',  // 내용
        accidentOccurred: '',  // 사고발생
        workFrequencyDaily: '',  // 작업빈도(日)
        safetyDevicesRegulationsYear: '',  // 안전장치/규정(年)
        musculoskeletalFactorsYear: '',  // 근골격계요인(年)
        regUserId: this.$store.getters.user.userId,
        editFlag: "C",
      });
    },
    removeF() {
      let selectData = this.$refs["tableF"].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit("ALERT", {
          title: "LBLGUIDE", // 안내
          message: "MSGNOITEMS", // 선택된 항목이 없습니다.
          type: "warning", // success / info / warning / error
        });
      } else {
        if (!this.matrix.deleteFrequencies) {
          this.matrix.deleteFrequencies = [];
        }
        this.$_.forEach(selectData, (item) => {
          if (
            this.$_.findIndex(this.matrix.deleteFrequencies, {
              ramMatrixFrequencyId: item.ramMatrixFrequencyId,
            }) === -1 &&
            item.editFlag !== "C"
          ) {
            this.matrix.deleteFrequencies.push(item);
          }
          this.matrix.frequencies = this.$_.reject(this.matrix.frequencies, {
            ramMatrixFrequencyId: item.ramMatrixFrequencyId,
          });
        });
        this.$refs["tableF"].$refs["compo-table"].clearSelection();
      }
    },
    addS() {
      this.matrix.strengties.push({
        ramMatrixStrengthId: uid(),
        ramMatrixId: this.popupParam.ramMatrixId,
        strength: "",
        strengthContents: '',  // 내용
        humanDamageYear: '',  // 인적피해(年)
        lossAmountYear: '',  // 손실금액(年)
        lineStopYear: '',  // Line 정지(年)
        workingEnvironmentYear: '',  // 작업환경(年)
        musculoskeletalSystemSpecialistYear: '',  // 근골격계양성자(年)
        regUserId: this.$store.getters.user.userId,
        editFlag: "C",
      });
    },
    removeS() {
      let selectData = this.$refs["tableS"].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit("ALERT", {
          title: "LBLGUIDE", // 안내
          message: "MSGNOITEMS", // 선택된 항목이 없습니다.
          type: "warning", // success / info / warning / error
        });
      } else {
        if (!this.matrix.deleteStrengties) {
          this.matrix.deleteStrengties = [];
        }
        this.$_.forEach(selectData, (item) => {
          if (
            this.$_.findIndex(this.matrix.deleteStrengties, {
              ramMatrixStrengthId: item.ramMatrixStrengthId,
            }) === -1 &&
            item.editFlag !== "C"
          ) {
            this.matrix.deleteStrengties.push(item);
          }
          this.matrix.strengties = this.$_.reject(this.matrix.strengties, {
            ramMatrixStrengthId: item.ramMatrixStrengthId,
          });
        });
        this.$refs["tableS"].$refs["compo-table"].clearSelection();
      }
    },
    accept() {
      this.$_.forEach(this.matrix.standards, (range) => {
        range.min = range.model.min;
        range.max = range.model.max;
      });
      this.haveRisk = false;
      this.setChart();
    },
    // acceptMatrixRisk() {
    //   if (this.series && this.series.length > 0) {
    //     this.$_.forEach(this.series, (item) => {
    //       this.$_.forEach(item.data, (riskInfo) => {
    //         if (Number(this.rangeComp.max) < Number(riskInfo.y)) {
    //           this.rangeComp.max = this.$_.clone(Number(riskInfo.y));
    //         }
    //       });
    //     });
    //   }
    // },
    acceptStandardMatrixRisk() {
      let ranges = [];
      if (this.matrix.standards && this.matrix.standards.length > 0) {
        this.$_.forEach(this.matrix.standards, (range) => {
          range.min = range.model.min;
          range.max = range.model.max;
          ranges.push({
            from: range.min,
            to: range.max,
            name: range.riskContents,
            color: range.color,
          });
        });
      }
      this.haveRisk = false;
      this.chartOptions.plotOptions.heatmap.colorScale.ranges = ranges;
      this.$refs["chart"].refresh();
    },
    addR() {
      this.matrix.standards.push({
        ramMatrixRiskStandardsId: uid(),
        model: {
          min: 0, 
          max: 0,
        },
        min: 0, 
        max: 0,
        checked: false,
        riskContents: '',
        color: '#c0c0c0',
        editFlag: 'C',
      });
    },
    removeR() {
      let selectData = this.$_.filter(this.matrix.standards, { checked: true, });
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        if (!this.matrix.deleteStandards) {
          this.matrix.deleteStandards = []
        }
        this.$_.forEach(selectData, item => {
          if (this.$_.findIndex(this.matrix.deleteStandards, { ramMatrixRiskStandardsId: item.ramMatrixRiskStandardsId }) === -1
            && item.editFlag !== 'C') {
              this.matrix.deleteStandards.push(item)
          }
          this.matrix.standards = this.$_.reject(this.matrix.standards, item)
        })
      }
    },
    datachange(range) {
      if (range.editFlag !== "C") {
        range.editFlag = "U";
        range.chgUserId = this.$store.getters.user.userId;
      }
    },
    saveMatrix() {
      if (this.popupParam.ramMatrixId) {
        this.mappingType = "PUT";
        this.saveUrl = transactionConfig.ram.matrix.update.url;
      } else {
        this.mappingType = "POST";
        this.saveUrl = transactionConfig.ram.matrix.insert.url;
      }
      this.$refs["editForm"].validate().then((_result) => {
        if (_result) {
          let saving = true;
          let isF =
            this.matrix.frequencies && this.matrix.frequencies.length > 0;
          let isS = this.matrix.strengties && this.matrix.strengties.length > 0;
          // 빈도에서 행은 추가가 되어있지만 값이 안들어간 경우를 체크
          if (isF) {
            saving = this.$comm.validTable(
              this.gridFColumns,
              this.matrix.frequencies
            );
          }
          // 강도에서 행은 추가가 되어있지만 값이 안들어간 경우를 체크
          if (isS) {
            saving = this.$comm.validTable(
              this.gridSColumns,
              this.matrix.strengties
            );
          }
          // 빈도 강도에 맞게 위험도가 표시되어 있는지 체크
          if (isF && isS) {
            // 위험도 매트릭스가 그려진 경우 true
            saving = this.series && this.series.length > 0;
            if (!saving) {
              window.getApp.$emit("ALERT", {
                title: "LBLGUIDE", // 안내
                message:
                // 빈도, 강도에 맞추어 위험도 Matrix가 만들어지지 않았습니다.\n\r빈도&강도 적용 버튼을 통해 위험도 Matrix를 확인 후 저장하시기 바랍니다.
                  "MSG0000404",
                type: "warning", // success / info / warning / error
              });
              return;
            }
            // series의 길이는 곧 빈도 길이, 빈도를 수정하여 행의 길이가 변경되었을 경우
            // 위험도 또한 변경 내용이 적용되어야 true
            saving = this.series.length === this.matrix.frequencies.length;
            if (!saving) {
              window.getApp.$emit("ALERT", {
                title: "LBLGUIDE", // 안내
                  // "변경된 빈도가 위험도 Matrix에 적용되지 않았습니다.\n\r빈도&강도 적용 버튼을 통해 위험도 Matrix를 확인 후 저장하시기 바랍니다.",
                message:
                  "MSG0000405",
                type: "warning", // success / info / warning / error
              });
              return;
            }
            // series 안에 존재하는 data의 길이는 곧 강도 길이, 강도를 수정하여 행의 길이가 변경되었을 경우
            // 위험도 또한 변경 내용이 적용되어야 true
            saving =
              this.series[0].data.length === this.matrix.strengties.length;
            if (!saving) {
              window.getApp.$emit("ALERT", {
                title: "LBLGUIDE", // 안내
                // 변경된 강도가 위험도 Matrix에 적용되지 않았습니다.\n\r빈도&강도 적용 버튼을 통해 위험도 Matrix를 확인 후 저장하시기 바랍니다.
                message:
                  "MSG0000405",
                type: "warning", // success / info / warning / error
              });
              return;
            }
          }
          // 위험도 기준을 변경한 후 변경된 내용을 적용하였는지
          if (this.matrix.standards && this.matrix.standards.length > 0) {
            this.$_.forEach(this.matrix.standards, (item) => {
              if (item.max !== item.model.max || item.min !== item.model.min) {
                saving = false;
                window.getApp.$emit("ALERT", {
                  title: "LBLGUIDE", // 안내
                  // 변경된 위험도기준이 위험도 Matrix에 적용되지 않았습니다.\n\r적용 버튼을 통해 위험도 Matrix를 확인 후 저장하시기 바랍니다.
                  message:
                    "MSG0000406",
                  type: "warning", // success / info / warning / error
                });
                return false;
              }
            });
          }
          if (saving) {
            window.getApp.$emit("CONFIRM", {
              title: "LBLCONFIRM",
              message: "MSGSAVE",
              // TODO : 필요시 추가하세요.
              type: "info", // success / info / warning / error
              // 확인 callback 함수
              confirmCallback: () => {
                this.matrix.regUserId = this.$store.getters.user.userId;
                this.matrix.chgUserId = this.$store.getters.user.userId;

                /**
                 * 위험도 셋팅
                 *
                 * series에 담겨 있는 정보를 토대로 risks를 setting처리
                 */
                this.matrix.risks = []; // 처음 가져온 값은 초기화 처리
                let fIndex = 0;
                this.$_.forEach(this.series, (item) => {
                  let sIndex = 0;
                  let frequency = this.matrix.frequencies[fIndex].frequency;
                  this.$_.forEach(item.data, (cell) => {
                    this.matrix.risks.push({
                      risk: cell.y,
                      frequency: frequency,
                      strength: this.matrix.strengties[sIndex].strength,
                    });
                    sIndex++;
                  });
                  fIndex++;
                });

                this.isSave = !this.isSave;
              },
              // 취소 callback 함수
              cancelCallback: () => {},
            });
          }
        } else {
          window.getApp.$emit("APP_VALID_ERROR");
        }
      });
    },
    saveMatrixCallback(result) {
      this.popupParam.ramMatrixId = result.data;
      window.getApp.$emit("APP_REQUEST_SUCCESS");
      this.getDetail();
    },
  },
};
</script>
<style lang="sass">
.range-input
  div > div
    height: 30px !important
  input
    padding: 0px !important

.risk-name::after
  direction: ltr
  display: inline-block
  font-family: 'Material Icons'
  font-size: 11px
  color: #2196f3
  font-style: normal
  font-weight: normal
  letter-spacing: normal
  text-transform: none
  white-space: nowrap
  word-wrap: normal
  -webkit-font-feature-settings: 'liga'
  -webkit-font-smoothing: antialiased
  content: 'edit'

.risk-standards [class*=col-]:first-child
  padding-top: 20px !important
.risk-standards .q-item
  padding-left: 0px !important
.risk-standards .customCardbody
  padding: 0 !important
  margin: 0 !important

.risk .q-field--with-bottom
  padding-bottom: 0px !important

.risk
  .custom-text-before
    font-size: 12px !important
    height: 22px !important
    min-height: 22px !important
    line-height: 22px
  .q-field--dense .q-field__inner
    padding-bottom: 0 !important
    padding-top: 3px !important
  .customText.q-field--dense .q-field__control, .customText.q-field--dense .q-field__marginal
    height: 22px !important
    min-height: 22px !important
</style>